@import './styles/variables';

body {
  margin: 0;
  font-family: 'Fredoka', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #479fec;
}

a:visited,
a:active {
  color: #2c7dc5;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.site-title {
  font-size: 1.6em;
  font-family: 'Playfair Display', serif;
  margin-right: 20px;
}

.site-title a {
  color: #fff;
  text-decoration: none;
}

.site-title .dot {
  color: $yellow-accent-color;
}

/***** HELPERS *****/

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex.responsive {
  flex-direction: column;
  @media screen and (min-width: $mobile_limit) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.flex.responsive-reverse {
  flex-direction: column-reverse;
  @media screen and (min-width: $mobile_limit) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.dark {
  background-color: $dark-accent-color;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.center {
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 30px;
}

.small-container {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 30px;
}

.tiny-container {
  margin: 0 auto;
  max-width: 600px;
  padding: 0 30px;
}

.right {
  text-align: right;
}

.info-message {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.shadow {
  flex: 1;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.35) 100%);
}

.responsive-margin-right {
  margin-right: 0px;
  @media screen and (min-width: $mobile_limit) {
    margin-right: 20px;
  }
}

.responsive-margin-left {
  margin-left: 0px;
  @media screen and (min-width: $mobile_limit) {
    margin-left: 20px;
  }
}

/* .container.small {
  max-width: 1000px;
} */

h1,
h2,
h3 {
  font-family: 'Inter', sans-serif;
  margin: 0px;
}

/***** CATEGORIES *****/

.category.health {
  /* color: #08b0c2; */
  background-color: #cdffff;
  border: 1px solid #cdffff;
}

.category.travel {
  /* color: #8f27ed; */
  background-color: #e2cdff;
  border: 1px solid #e2cdff;
}

.category.cooking {
  /* color: #2751ed; */
  background-color: #cdd6ff;
  border: 1px solid #cdd6ff;
}

.category.finance {
  /* color: green; */
  background-color: #c3f1c3;
  border: 1px solid #c3f1c3;
}

.category.legal {
  /* color: #ed2727; */
  background-color: #ffcdcd;
  border: 1px solid #ffcdcd;
}

.category.business {
  /* color: #ed8c27; */
  background-color: #ffebcd;
  border: 1px solid #ffebcd;
}

.category.education {
  /* color: #ed8c27; */
  background-color: #ffafd7;
  border: 1px solid #ffafd7;
}

.category.real.estate {
  /* color: #ed8c27; */
  background-color: #e0ffaf;
  border: 1px solid #e0ffaf;
}

.category.other {
  /* color: #ed8c27; */
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

/********* FORMS *********/

input,
textarea,
select {
  padding: 15px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 14px;
  background-color: #fafafa;
  box-sizing: border-box;
  /*box-shadow: 2px 2px 3px #f0f0f0 inset;*/
  border-radius: 5px;
  // font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;
}
body select {
  padding: 0px 15px;
}

input[type='checkbox'] {
  width: 30px;
  box-shadow: none;
}

input[disabled],
textarea[disabled],
select[disabled] {
  background-color: #f0f0f0;
  color: #757575;
}

input:focus,
textarea:focus,
select:focus {
  background-color: #fff;
  box-shadow: 0px 0px 3px 3px rgba(244, 188, 2, 0.3);
  border: 1px solid #f3bb03;
  outline: #f3bb03;
}

input.error,
textarea.error,
select.error {
  border: 1px solid #ef4949;
}

select {
  height: 43px;
}
select[multiple] {
  height: auto;
}

/********* MISC *********/

.no-results {
  text-align: center;
  margin-top: 50px;
}

.no-results .nr-title {
  font-size: 1.3em;
  font-weight: bold;
  color: #181818;
}

.no-results .nr-body {
  margin-top: 10px;
  color: #999;
}

.stripe-logo {
  background: url('https://yencilstatic.s3.us-east-2.amazonaws.com/Stripe+wordmark+-+blurple.svg');
}
