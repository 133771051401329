@import '../../styles/variables';

#table-data .header {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  align-items: center;
  box-shadow: 0px 2px 5px #dddddd;
  margin-bottom: 15px;
}

#table-data .data-row {
  display: flex;
  margin-top: 15px;
  padding: 0px 20px;
}

#table-data .header .data-row {
  margin-top: 0px;
  padding: 0px;
}

#table-data .data-row .data-col {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 5px;
}

#table-data .data-row .data-col.selected {
  overflow: visible;
  text-overflow: unset;
  white-space: unset;
  border: 2px solid $yellow-accent-color;
  padding: 5px;
}
