.form-render .row {
  display: flex;
}

.form-render .row.no-flex {
  display: block;
}

.form-render .row.editor-row {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.form-render .row .form-field {
  flex: 1;
  margin: 15px 10px;
}

.form-render .row .form-field .form-field-info {
  margin-top: 5px;
  font-size: 0.8em;
  color: #7d7d7d;
}

.form-render .row .form-field.form-field-price {
  font-size: 1.2em;
}

.form-render .row .form-field.form-field-price input {
  text-align: right;
  font-size: 1.2em;
}

.form-render .row.no-flex .form-field {
  display: inline-block;
  margin: 10px;
}

.form-render .form-controls {
  margin: 10px;
  text-align: right;
}

.form-render label {
  display: flex;
  font-weight: bold;
  font-size: 12px;
  color: #575757;
  margin: 0px 0px 4px 2px;
  padding: 0px;
}

.form-render .checkbox-title {
  font-weight: normal;
  font-size: 14px;
}

.form-render label .fa {
  color: #ef4949;
}

.form-render .form-field-checkbox-holder {
  text-align: center;
}

.form-render select.placeholder {
  color: #757575;
}
