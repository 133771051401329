@import '../../styles/variables';

.toggler {
    width: 50px;
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    cursor: pointer;
    transition: all .15s ease-out;
}

.toggler.small {
    width: 35px;
    border-radius: 10px;
}

.toggler.isOn {
    background-color: #ffdf74;
    border: 1px solid $yellow-accent-color;
}

.toggler-disc {
    height: 30px;
    width: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 2px 5px #8f8f8f;
    margin-left: 0px;
    transition: all .15s ease-out;
}

.small .toggler-disc {
    height: 20px;
    width: 20px;
    border-radius: 15px;
}

.isOn .toggler-disc {
    margin-left: 20px;
}

.small.isOn .toggler-disc {
    margin-left: 15px;
}