@use 'sass:math';
@import './variables';

$loader-color: #0052ec !default;
$loader-size: 56px !default;
$loader-height: 20px !default;
$loader-border-size: 8px !default;
$loader-gap: 12px !default;
$loader-animation-duration: 1s !default;
$size: 60px;
$align: middle;
$color: $yellow-accent-color;
$duration: 1s;
$border-size: 8px;

@mixin loader-rotate {
  @keyframes loader-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.loader {
  width: $size;
  height: $size;
  border: $border-size solid rgba($color, 0.25);
  border-top-color: $color;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate $duration linear infinite;

  @if ($align == center) {
    margin: 0 auto;
  }

  @if ($align == middle) {
    top: 50%;
    margin: -(math.div($size, 2)) auto 0;
  }

  @include loader-rotate;
}
