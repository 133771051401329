#home-content {
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: row;
}

#content {
  flex: 1 1;
  padding: 40px;
}

.home-section-panel {
  background-color: rgba(black, 0.05);
  border-radius: 5px;
  margin: 20px;
  padding: 30px;
  display: inline-flex;
  width: 170px;
  flex-direction: column;
  text-align: center;
  cursor: pointer;

  .value {
    font-size: 3em;
  }

  .title {
    margin-top: 10px;
  }

  .loader {
    margin: 0 auto;
  }
}

.home-section-panel:hover {
  background-color: #fff;
}
