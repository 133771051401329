@import '../../styles/variables';

#activity-graph .grid .tick line {
  stroke: #ccc;
  fill: #ccc;
}

#activity-graph .domain {
  display: none;
}

#activity-graph .key {
  margin-bottom: 20px;
  display: flex;
  color: #888;

  .date {
    flex: 1;
    text-align: right;
  }

  .date.active {
    color: $dark-accent-color;
  }

  .key-item {
    display: inline-flex;
    margin-right: 25px;
    align-items: center;
    flex-direction: column;
    color: #888;

    .info {
      display: flex;
      align-items: center;
      padding-left: 8px;
    }

    .number {
      font-size: 1.5em;
      text-align: left;
      display: flex;
      width: 100%;
      padding-left: 8px;
    }

    .number.active {
      color: $dark-accent-color;
    }

    .title {
      font-size: 0.8em;
      flex: 1;
    }

    .color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
