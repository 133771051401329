@import '../../styles/variables';

#menu {
  display: block;
  position: sticky;
  padding-top: 30px;
  width: 250px;
  top: 0;
}

#menu .link {
  padding: 10px 10px 10px 25px;
  cursor: pointer;
}

#menu .link.active {
  background-color: #fff;
  box-shadow: 0px 2px 5px #dddddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
