@import '../../styles/variables';

#nav {
  font-size: 1.4em;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  background-color: #fff;
  color: $dark-accent-color;
}

#nav img {
  margin-bottom: -10px;
}

#nav .toggle-section {
  display: flex;
  flex-basis: content;
  width: 160px;
}

#nav .toggle-section .toggler {
  height: fit-content;
  margin-left: 20px;
}
