#login {
  margin-top: 100px;
}

#login .small-container {
  max-width: 600px;
}

#login .login-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin-bottom: 15px;
}

#login .login-title img {
  margin-right: 10px;
  margin-bottom: -4px;
}
