#graph-manager {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 5px #dddddd;
  margin-bottom: 15px;
}

#graph-manager .title {
  flex: 1;
  font-size: 1.3em;
}
